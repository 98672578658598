import { render, staticRenderFns } from "./account.vue?vue&type=template&id=3cbb63a8&scoped=true&"
import script from "./account.vue?vue&type=script&lang=ts&"
export * from "./account.vue?vue&type=script&lang=ts&"
import style0 from "./account.vue?vue&type=style&index=0&id=3cbb63a8&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cbb63a8",
  null
  
)

export default component.exports